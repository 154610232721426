import {
  Avatar,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Backdrop,
  Button,
  TextField,
} from "@mui/material";
import { useLiffContext } from "../../LiffContext";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { httpClient } from "../utils/HttpClient";
import Swal from "sweetalert2";
import CardHome from "./CardHome";
import { ROLE_NAME } from "../../config";

export default function Home() {
  const { liffFn } = useLiffContext();
  const [profile, setProfile] = useState();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [tell, setTell] = useState("");
  //   const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setOpen(true);
      const result = await httpClient.get("/user/check-regis");
      setOpen(false);
      if (result.data.status === true) {

        if (result.data.user.dbUser) {
          await setProfile(result.data.user);
          await setTell(result.data.user.dbUser.phoneNumber);
        } else {
          //   setLoading(false);
          navigate("/regis");
        }
      }
    })();
  }, []);

  const handleSubmit = async () => {
    setOpen(true)
    const result = await httpClient.post("/user/update-phone", {
      phoneNumber: tell
    })
    await setProfile((old) => {
      old.dbUser.phoneNumber = tell
      return old
    })
    setEdit(false)
    setOpen(false)
  }

  return (
    <Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {profile && (
        <div>
          <Paper sx={{ borderRadius: 10, py: 2, px: 1, mt: 1 }} elevation={3}>
            <Typography variant="h5" sx={{ my: 0.5 }} align="center">
              ยินดีต้อนรับ
            </Typography>
            <Divider textAlign="left" sx={{ my: 1 }}>
              <Chip
                sx={{ lineHeight: 1.8 }}
                label={<strong>ข้อมูลผู้ใช้งาน</strong>}
              />
            </Divider>
            <Grid container alignItems={"center"}>
              <Grid item xs={4}>
                <Avatar
                  alt="sale profile"
                  src={profile.lineUser.pictureUrl}
                  sx={{ width: 100, height: 100 }}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle1">
                  <strong>
                    {profile.dbUser.firstName} {profile.dbUser.lastName}
                  </strong>
                </Typography>
                <Typography variant="subtitle2">
                  <strong>บริษัท:</strong> {profile.dbUser.Company.name}
                </Typography>
                <Typography variant="subtitle2">
                  <strong>สาขา:</strong> {profile.dbUser.Branch.name}
                </Typography>
                <Typography variant="subtitle2">
                  <strong>ฝ่าย:</strong> {profile.dbUser.Division.name}
                </Typography>
                <Typography variant="subtitle2">
                  <strong>แผนก:</strong> {profile.dbUser.SubDivision.name}
                </Typography>
                <Typography variant="subtitle2">
                  <strong>เบอร์มือถือ:</strong> {profile.dbUser.phoneNumber}
                </Typography>
                {/* <Typography variant="subtitle2">
                  <strong>เบอร์ภายใน:</strong> {profile.dbUser.internalNumber}
                </Typography> */}
                {!edit && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setEdit(true)}
                  >
                    แก้ไขเบอร์
                  </Button>
                )}
                {edit && (
                  <>
                    <TextField
                      sx={{ my: 1 }}
                      variant="outlined"
                      fullWidth
                      size="small"
                      label="เบอร์มือถือ"
                      value={tell}
                      onChange={(e) => {
                        e.preventDefault();
                        setTell(e.target.value);
                      }}
                    />
                    <span>
                      <Button
                        sx={{ mx: 1 }}
                        variant="contained"
                        size="small"
                        onClick={() => handleSubmit()}
                      >
                        บันทึก
                      </Button>
                      <Button
                        sx={{ mx: 1 }}
                        variant="outlined"
                        size="small"
                        onClick={() => setEdit(false)}
                      >
                        ยกเลิก
                      </Button>
                    </span>
                  </>
                )}
              </Grid>
            </Grid>
            {(profile.dbUser.roleAdmin ||
              profile.dbUser.roleRepairMan ||
              profile.dbUser.roleAssessor) && (
                <div>
                  <Divider textAlign="left" sx={{ my: 1 }}>
                    <Chip label={<strong>ระดับผู้ใช้งาน</strong>} />
                  </Divider>
                  {profile.dbUser.roleRepairMan && (
                    <Chip
                      label={ROLE_NAME.roleRepairMan}
                      variant="outlined"
                      sx={{ m: 0.2 }}
                    />
                  )}
                  {profile.dbUser.roleAssessor && (
                    <Chip
                      label={ROLE_NAME.roleAssessor}
                      variant="outlined"
                      sx={{ m: 0.2 }}
                    />
                  )}
                  {profile.dbUser.roleAdmin && (
                    <Chip
                      label={ROLE_NAME.roleAdmin}
                      variant="outlined"
                      sx={{ m: 0.2 }}
                    />
                  )}
                </div>
              )}
            {profile.dbUser.UserBranches.length > 0 && (
              <div>
                <Divider textAlign="left" sx={{ my: 1 }}>
                  <Chip label={<strong>สาขาที่ดูแล</strong>} />
                </Divider>
                {profile.dbUser.UserBranches.map((branch, key) => (
                  <Chip
                    label={branch.Branch.name}
                    variant="outlined"
                    key={key}
                    sx={{ m: 0.2 }}
                  />
                ))}
              </div>
            )}
          </Paper>
          <br />

          <CardHome
            title="แจ้งซ่อมใหม่"
            body="กดที่นี่เพื่อแจ้งงานซ่อมใหม่"
            link="/new-job"
          />

          <CardHome
            title="งานแจ้งซ่อมของฉัน"
            body="กดที่นี่เพื่อติดตามสถานะงานแจ้งซ่อมของฉัน"
            link="/my-job"
          />

          {profile.dbUser.roleRepairMan && (
            <div>
              <Divider textAlign="left" sx={{ my: 3 }}>
                <Chip
                  color="primary"
                  label={<strong>{ROLE_NAME.roleRepairMan}</strong>}
                />
              </Divider>
              <CardHome
                title="งานซ่อมที่ได้รับมอบหมายให้ซ่อม"
                body="กดที่นี่เพื่อดูงานที่ฉันได้รับมอบหมายให้ทำการซ่อม"
                link="/my-repair"
              />
            </div>
          )}

          {profile?.dbUser?.TeamMembers?.length>0 && profile.dbUser.TeamMembers.find(member=>member.role === "head") && (
            <div>
              <Divider textAlign="left" sx={{ my: 3 }}>
                <Chip
                  color="primary"
                  label={<strong>หัวหน้าทีม</strong>}
                />
              </Divider>
              <CardHome
                title={<div>งานซ่อมในทีมของฉัน <Chip label="ใหม่" color="error" /></div>}
                body="กดที่นี่เพื่อดูงานซ่อมในทีมของฉัน"
                link="/all-job-in-team"
              />
            </div>
          )}

          {profile.dbUser.roleAssessor && (
            <div>
              <Divider textAlign="left" sx={{ my: 3 }}>
                <Chip
                  color="primary"
                  label={<strong>{ROLE_NAME.roleAssessor}</strong>}
                />
              </Divider>
              {/* <CardHome
                title="งานซ่อมที่ต้องประเมิน"
                body="กดที่นี่เพื่อดูงานที่ฉันต้องประเมิน"
                link="/my-estimate"
              />
              <CardHome
                title="งานซ่อมทั้งหมดที่ฉันดูแล"
                body="กดที่นี่เพื่อดูงานซ่อมทั้งหมดที่ฉันดูแล"
                link="/my-all-estimate"
              /> */}
              <CardHome
                title={<div>งานแจ้งซ่อมทั้งหมด</div>}
                body="กดที่นี่เพื่อดูงานแจ้งซ่อมทั้งหมด"
                link="/new-my-all-estimate"
              />
            </div>
          )}

          {profile.dbUser.roleAdmin && (
            <div>
              <Divider textAlign="left" sx={{ my: 3 }}>
                <Chip
                  color="primary"
                  label={<strong>{ROLE_NAME.roleAdmin}</strong>}
                />
              </Divider>
              <CardHome
                title="งานที่ต้องเปลี่ยนหมวด"
                body="กดที่นี่เพื่อดูงานที่ต้องเปลี่ยนหมวด"
                link="/change-job-type"
              />
              <CardHome
                title="จัดการผู้ใช้"
                body="กดที่นี่เพื่อจัดการผู้ใช้ทั้งหมดในระบบ"
                link="/users"
              />
              <CardHome
                title="ส่งออกข้อมูลงานแจ้งซ่อมทั้งหมด"
                body="กดที่นี่เพื่อบันทึกงานแจ้งซ่อมทั้งหมดในระบบ ในรูปแบบ Excel"
                link="/export"
              />
              <CardHome
                title={<div>จัดการทีมทั้งหมด</div>}
                body="กดที่นี่เพื่อจัดการทีมทั้งหมด"
                link="/teams"
              />
              <CardHome
                title={<div>ดึงรายงานงานทั้งหมด</div>}
                body="กดที่นี่เพื่อดึงรายงานงานทั้งหมด"
                link="/all-job"
              />
              <CardHome
                title={<div>กราฟ</div>}
                body="กดที่นี่เพื่อดูกราฟ"
                link="/dashboard"
              />
            </div>
          )}
        </div>
      )}
      <br />
      <br />
      <Typography gutterBottom variant="body1" color={"black"} textAlign="center" >version: 2.0.0</Typography>
    </Container>
  );
}
